import React from "react";
import PageWrapper from "../components/PageWrapper";
import StyleMap from "../types/styleMap";

interface VerifiedViewProps {
  continueUrl: string;
}

const VerifiedView: React.FC<VerifiedViewProps> = ({ continueUrl }) => {
  return (
    <PageWrapper>
      <div style={styles.text}>
        <h3>You email has been verified</h3>
        <div>You may return to the app to continue signing up</div>
      </div>
      {/* <button onClick={() => returnToApp(continueUrl)} style={styles.button}>
        Return to Fizz App
      </button> */}
    </PageWrapper>
  );
};

// const returnToApp = (continueUrl: string) => {
//   window.open(continueUrl, "_self");
// };

const styles: StyleMap = {
  button: {
    margin: "20px 0",
    backgroundColor: "black",
    padding: "12px 48px",
    borderWidth: 0,
    borderRadius: 24,
    boxShadow: "0 1px 4px rgba(0, 0, 0, 1)",
    color: "yellow",
    fontSize: 16,
  },
  text: {
    marginBottom: 16,
  },
};

export default VerifiedView;
