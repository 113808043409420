import React, { useEffect, useState } from "react";
import FizzLogo from "../assets/fizzBlackLogo.png";
import Swal from "sweetalert2";
import { Api } from "../api/api";
import { useParams } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { applyActionCode, Auth, getAuth } from "firebase/auth";
import VerifiedView from "./Verified";
import ErrorView from "./Error";
import { firebaseConfig } from "../config";
import StyleMap from "../types/styleMap";

export const Redirecting = () => {
  const params = useParams();
  const shortenedId = params["id"];

  const [isVerified, setIsVerified] = useState(false);
  const [continueUrl, setContinueUrl] = useState<string | null>(null);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    handleRedirect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEmailVerification = async (
    auth: Auth,
    searchParams: URLSearchParams
  ) => {
    const actionCode = searchParams.get("oobCode") || "";
    const continueUrl = searchParams.get("continueUrl") || "";

    applyActionCode(auth, actionCode)
      .then((response) => {
        setIsVerified(true);
        setContinueUrl(continueUrl);
      })
      .catch((e) => {
        // Todo: Log error
        console.error(e);
        setHasError(true);
      });
  };

  const handleRedirect = async () => {
    try {
      if (shortenedId) {
        const res = await Api.getActualUrlFromShortenedId(shortenedId);
        const { url } = res.data;

        const parsedUrl = new URL(url);
        const searchParams = parsedUrl.searchParams;

        const mode = searchParams.get("mode");

        const app = initializeApp(firebaseConfig);
        const auth = getAuth(app);

        switch (mode) {
          case "verifyEmail":
            handleEmailVerification(auth, searchParams);
            break;

          default:
            // Invalid mode
            setHasError(true);
        }
      }
    } catch (e) {
      console.error(e);
      Swal.fire({
        title: "Oops",
        text: "Something went wrong when verifying your email. Please contact support@joinfizz.com for assistance.",
        icon: "error",
      });
    }
  };

  if (isVerified && continueUrl) {
    return <VerifiedView continueUrl={continueUrl} />;
  }

  if (hasError) {
    return <ErrorView />;
  }

  return (
    <div style={styles.container}>
      <img alt="" src={FizzLogo} style={styles.fizzLogo} />
      <label style={styles.redirectingLbl}>Loading. One moment...</label>
    </div>
  );
};

const styles: StyleMap = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    height: "100%",
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 50,
    fontFamily: "Arial",
    fontWeight: 600,
  },
  fizzLogo: {
    width: "50%",
    maxWidth: 60,
  },
  redirectingLbl: {
    fontSize: 15,
    paddingTop: 20,
  },
};
