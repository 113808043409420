const firebaseConfigProd = {
  apiKey: "AIzaSyBTYWqByYEXUllgJCiTbkQ_gq6c4NLIuEY",
  authDomain: "prod-fizz-mobile-app.firebaseapp.com",
  projectId: "prod-fizz-mobile-app",
  storageBucket: "prod-fizz-mobile-app.appspot.com",
  messagingSenderId: "723858929555",
  appId: "1:723858929555:web:9ace5d315e48d756c99a66",
  measurementId: "G-K2PEZ72444",
};

const firebaseConfigStaging = {
  apiKey: "AIzaSyDqtq7tATWfrI4tUlP5tjr53R52ZqcZ_H0",
  authDomain: "fizz-mobile-app.firebaseapp.com",
  projectId: "fizz-mobile-app",
  storageBucket: "fizz-mobile-app.appspot.com",
  messagingSenderId: "961427389982",
  appId: "1:961427389982:web:87ee2e68026f0aaa77f80f",
  measurementId: "G-D90KSEMEGK",
};

export const isProd = process.env.REACT_APP_ENV === "production";
export const firebaseConfig = isProd
  ? firebaseConfigProd
  : firebaseConfigStaging;
