import React from "react";
import PageWrapper from "../components/PageWrapper";

const ErrorView = () => {
  return (
    <PageWrapper>
      <h3>Try verifying your email again</h3>
      <div>
        Your request to verify your email has expired or the link has already
        been used
      </div>
    </PageWrapper>
  );
};

export default ErrorView;
