import React, { PropsWithChildren } from "react";
import StyleMap from "../types/styleMap";

const PageWrapper = ({ children }: PropsWithChildren) => {
  return (
    <div style={styles.outer}>
      <div style={styles.container}>{children}</div>
    </div>
  );
};

const styles: StyleMap = {
  outer: {
    height: "98vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    fontFamily: "Arial",
    borderRadius: 4,
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: 24,
    padding: "24px 24px",
    textAlign: "center",
    boxShadow: "0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05)",
    maxWidth: 480,
  },
};

export default PageWrapper;
